import React from "react"
import "./style.scss"

const Secrion2 = () => {
  return (
    <div className="section2">
      <h1>Już wktótce...</h1>
      <p>Masz pytania? Zadzwoń: 505-152-565</p>
    </div>
  )
}

export default Secrion2
